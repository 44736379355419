<template>
  <!-- ======= Idef Section ======= -->
  <section id="about" class="about"></section>

  <div class="idef">
    <h1>IDEF 21</h1>
    <h3>WE ARE WAITING FOR YOU IN HALL 10 – STAND 1000 AT IDEF 2021 FAIR.</h3>
    <img src="@/assets/enidef.jpg" style="max-width: 800px; width: 70%" />
  </div>

  <!--End About Section -->
</template>

<script>
export default {
  name: "enIdef",
};
</script>

<style scoped>
.idef {
  padding-bottom: 0px;
}
.idef h3 {
  margin-bottom: 50px;
}
</style>
