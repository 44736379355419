<template>
  <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-md-6 footer-links"></div>

          <div class="col-lg-2 col-md-6 footer-links"></div>

          <div class="col-lg-4 col-md-6">
            <div class="footer-info">
              <h3>Açık Deniz Bilişim</h3>
              <p>
                Barbaros Mah. Akzambak Sok. <br />
                Uphill Court A1 Blok No:3 Kat:4 D:22 34746 <br />
                Batı Ataşehir/İstanbul <br /><br />
                <strong>Telefon: </strong> +90 216 688 85 67<br />
                <strong>E-posta: </strong> info@acikdenizbilisim.com<br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; <strong><span>Açık Deniz Bilişim</span></strong
        >. Tüm Hakları Saklıdır
        <a href="https://www.akcaysirketlergrubu.com" target="blank"
          ><P>AKÇAY ŞİRKETLER GRUBU</P></a
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
<style scoped>
.container .copyright a {
  color: white;
}
</style>
