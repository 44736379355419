<template>
  <!-- ======= Cta Section ======= -->
  <section id="cta" class="cta">
    <div class="container" data-aos="zoom-in">
      <div class="text-center">
        <p>
          Açık Deniz Bilişim olarak kurumlara özgü geliştirmiş olduğumuz
          uygulamalarımızı deneyimlemeniz için sabırsızlanıyoruz. Eğer
          kurumunuzun geliştirdiğimiz ürünlerden herhangi birine ihtiyacı varsa
          bizimle iletişime geçmekten çekinmeyin.
        </p>
        <a class="cta-btn" href="#">Hemen İletişime Geç</a>
      </div>
    </div>
  </section>
  <!-- End Cta Section -->
</template>

<script>
export default {
  name: "Cta",
};
</script>
