<template>
  <TopBar />
  <Header />

  <Hero />

  <main id="main">
    <div style="position: relative">
      <img
        src="../assets/aksiyon/Aksiyon-Brosur-Resim-Small.jpg"
        style="
          background-size: cover;
          background-position: center center;
          width: 100%;
          height: 100%;
        "
      />
      <div
        style="
          position: absolute;
          font-size: 4rem;
          color: #00bcd4;
          width: 80%;
          top: 20%;
          left: 10%;
        "
      >
        <img
          src="../assets/aksiyon/aksiyon_logo_buyuk_mavi.png"
          style="width: 60%"
        />
        <h1>KURUM İÇİ <br />YÖNETİŞİM YAZILIMI</h1>
      </div>
    </div>
    <div class="row" style="height: 800px; position: relative">
      <div class="col-sm-6" style="padding-top: 100px">
        <p>
          <span
            style="
              top: 80px;
              color: #5f727f;
              display: inline-block;
              max-width: 600px;
              margin: 0 auto;
            "
          >
            <strong>Aksiyon</strong> geniş veri işleme, öğrenme, çıkarsama,
            karar verme ve algılama araçları sağlayan geri izleme, karar
            ağaçları, arama mekanizmaları gibi çeşitli teknikleri kullanan bulut
            teknolojisi tabanlı <strong>yönetişim, planlama</strong> ve
            <strong>paylaşım</strong> sistemidir.
          </span>
        </p>
        <h5><span style="color: #00bcd4">KAZANIMLARIMIZ</span></h5>
        <ul>
          <li>Yüksek Karlılık</li>
          <li>Verimli İş Yönetimi ve Denetimi</li>
          <li>Rekabet Gücü Artışı</li>
          <li>Kurumsal Güven</li>
          <li>Kurumsal Bilgi Artışı</li>
          <li>Hedef Odaklı İş Yönetimi</li>
        </ul>
      </div>
      <div class="col-sm-6" style="padding-top: 100px">
        <img src="../assets/aksiyon/Aksiyon-Resim.jpg" style="width: 80%" />
      </div>
    </div>

    <div style="background-color: #99d6ff">
      <div
        class="container row"
        style="
          margin-right: auto;
          margin-left: auto;
          padding-left: 21px;
          padding-right: 21px;
        "
      >
        <h1 style="text-align: center; margin-top: 70px">
          <span class="light" style="color: #46485c"
            >AKSİYON NEDİR?<br />
          </span>
        </h1>
        <div class="col-sm-6" style="margin-right: 0; margin-top: 35px">
          <div style="margin-top: 35px; padding-bottom: 100px">
            <p>
              AKSİYON yazılımı ile kurum çalışanlarının oluşturulan etkinlik ve
              faaliyetler kapsamında görevlendirmeleri ve bu görevlendirmeler
              ile ilgili ihtiyaç duydukları bilgiler, fiziksel donanımlar,
              ilgili dökümanlar konusunda bilgilendirilmesi, faaliyetin zaman ve
              iş planlamasını izleme, görevlerin gerçekleştirme durumunu görme
              ve kendilerinin görevlendirmeleri ile ilgili durum bildirimi yapma
              ve bu bildirimlerle ilgili çeşitli multimedia ve diğer dökümanları
              ekleme sağlanmaktadır.
            </p>
          </div>
          <img
            class="rimg"
            src="../assets/aksiyon/4-31.jpg"
            style="width: 100%; margin-top: 35px"
          />
          <div
            style="margin-top: 35px; padding-top: 100px; padding-bottom: 100px"
          >
            <p>
              AKSİYON yazılımı üzerinde oluşturulan etkinlik ve faaliyetlerin
              içerik, görevlendirme, fiziksel donanım gibi öğelerinin, gerekli
              yetkilendirilmeler çerçevesinde hazırlanması, zamanlanması, durum
              bilgilerinin sağlanması gerçekleştirilmektedir.
            </p>
          </div>
          <img
            class="rimg"
            src="../assets/aksiyon/5-30.jpg"
            style="width: 100%; margin-top: 35px"
          />
        </div>

        <div class="col-sm-6" style="">
          <img
            class="rimg"
            src="../assets/aksiyon/7-27.jpg"
            style="width: 100%; margin-top: 35px"
          />
          <div
            style="margin-top: 35px; padding-top: 100px; padding-bottom: 100px"
          >
            <p>
              Burada amaç, projelenen etkinlik ve tüm yönetim faaliyetlerinin
              AKSİYON yazılımı üzerinde dijital ortama aktarılarak, etkinlik ile
              ilgili tüm içeriğe, görevlere ve diğer öğelere bir bütünlük içinde
              kolay ulaşım ve faaliyetlerle ilgili anında iletişim ile diğer
              yöntemlerle uzun süreler alacak, emek ve kaynak israfına yol
              açacak süreçlerin daha etkin ve verimli şekilde
              gerçekleştirilmesidir.
            </p>
          </div>
          <img
            class="rimg"
            src="../assets/aksiyon/9-21.jpg"
            style="width: 100%; margin-top: 35px"
          />
          <div
            style="margin-top: 35px; padding-top: 100px; padding-bottom: 100px"
          >
            <p>
              Aksiyon bir yönetişim yazılımı olarak hemen hemen her firma, kurum
              ya da gruplara uygun bir iş yönetimi ve ekip içi paylaşım
              yazılımıdır. Temiz ve modern yapısı, performansı ve güvenliği ile
              her açıdan rahat kullanım düşünülerek hazırlanmıştır. Güvenliği
              ile de tüm verilerinizin kaybolmaması ve güvende olması için özel
              çözümler sunmaktadır.
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer />
  <a
    href="#"
    class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i
  ></a>
  <div id="preloader" />
</template>

<script>
import router from "../router";
export default {
  name: "Aksiyon",
  components: {
    Header,
    Footer,
    TopBar,
  },

  data: function () {
    return {
      loader: "",
    };
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
    );
    document.body.appendChild(plugin);
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.min.js"
    );
    document.body.appendChild(plugin2);
  },
  methods: {
    navigate() {
      router.push({ name: "Aksiyon" });
    },
  },
};

import Header from "./Header";
import TopBar from "./topbar/TopBar";
import Footer from "./Footer";
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css";
@import "~boxicons/css/boxicons.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "../styles/style.css";
.right {
  position: absolute;
  top: 80px;
  right: 50px;
}
.rimg {
  border-radius: 50px;
}
</style>
