<template>
  <TopBar />
  <enHeader />

  <Hero />

  <main id="main">
    <div style="position: relative">
      <img
        src="../assets/ambar/Ambar-Brosur-On-Small.jpg"
        style="
          background-size: cover;
          background-position: center center;
          width: 100%;
          height: 100%;
        "
      />
      <div
        style="
          position: absolute;
          font-size: 46px;
          color: rgb(12, 176, 243);
          text-align: center;
          width: 80%;
          top: 30%;
          left: 10%;
        "
      >
        <img src="../assets/ambar/Ambar-Logo-1.png" style="width: 60%" />
        <h1>A secure repository for your documents</h1>
      </div>
    </div>
    <div class="row" style="height: 1000px; position: relative">
      <div class="col-sm-6" style="padding-top: 100px">
        <p>
          <span
            style="
              top: 80px;
              color: #5f727f;
              display: inline-block;
              max-width: 600px;
              margin: 0 auto;
            "
          >
            Access your documents from anywhere with <strong>Ambar</strong>
            <br />
            Access your documents from anywhere with Ambar (A solution that runs
            on your own servers instead of Google Drive or Dropbox) Enrich your
            work with outward sharing of documents, in-team shares,
            authorizations, version control, and comments.
          </span>
        </p>
        <h5>Works on Any Device</h5>
        <ul>
          <li>
            An easy and modern web interface, desktop (windows, mac, linux) and
            mobile applications,
          </li>
          <li>
            The synchronization feature provides easy and fast access to the
            same documents on every device.
          </li>
        </ul>
        <h5>Ultimate Security</h5>
        <ul>
          <li>
            Manage your documents securely with end-to-end encryption methods,
          </li>
          <li>
            Host documents on your own servers and easily control external and
            internal access
          </li>
        </ul>
        <h5>Integrations</h5>
        <ul>
          <li>
            It can integrate with many systems such as FTP, Windows Network,
            SharePoint, NFS,
          </li>
          <li>
            You can manage these processes easily, quickly and reliably with low
            cost by making easy configuration.
          </li>
        </ul>
      </div>
      <div class="col-sm-6" style="padding-top: 100px">
        <img
          src="../assets/ambar/Ambar-Brosur-On-Small.jpg"
          style="width: 80%"
        />
      </div>
    </div>

    <div style="background-color: #cef0fd">
      <div
        class="container row"
        style="
          margin-right: auto;
          margin-left: auto;
          padding-left: 21px;
          padding-right: 21px;
        "
      >
        <h1 style="text-align: center; margin-top: 70px">
          <span class="light" style="color: #46485c">WHY AMBAR?<br /> </span>
        </h1>
        <div class="col-sm-6" style="margin-right: 0; margin-top: 35px">
          <div style="margin-top: 35px; padding-bottom: 100px">
            <p>
              Employees should have easy access to documents and be able to
              easily share documents with other employees. In fact, sharing it
              outside and doing it securely is very important for a business to
              protect its information. With Ambar, you can keep your documents
              securely and easily share them with your internal and external
              customers, suppliers, partners and individuals. Those you share
              with and authorized people can access these documents. You can run
              it on your own servers or you can use it comfortably by using our
              cloud server service.
            </p>
          </div>
          <img
            class="rimg"
            src="../assets/ambar/4-31.jpg"
            style="width: 100%; margin-top: 35px"
          />
          <div
            style="margin-top: 35px; padding-top: 100px; padding-bottom: 100px"
          >
            <ul>
              <li>
                It works like Dropbox or Google Drive, but you set the limits.
              </li>
              <li>
                You can create temporary and temporary pools such as Wetransfer.
              </li>
              <li>You can give quotas to certain groups.</li>
              <li>
                Easy working from your computer with the Desktop application
              </li>
              <li>
                From the web interface, the admin can easily make all the
                adjustments.
              </li>
              <li>You can share securely both internally and externally.</li>
              <li>You can see the document history by keeping the version.</li>
              <li>You can back up your documents with agile backup.</li>
              <li>Office documents can be collaborated on the web.</li>
            </ul>
          </div>
          <img
            class="rimg"
            src="../assets/ambar/7-27.jpg"
            style="width: 100%; margin-top: 35px"
          />
        </div>

        <div class="col-sm-6" style="">
          <img
            class="rimg"
            src="../assets/ambar/5-30.jpg"
            style="width: 100%; margin-top: 35px"
          />
          <div
            style="margin-top: 35px; padding-top: 100px; padding-bottom: 100px"
          >
            <h6>PUBLIC CLOUD SOLUTIONS NOT ENOUGH?</h6>
            <p>
              Many institutions use solutions such as Dropbox and Google Drive,
              and these solutions are not compatible with privacy policies. It
              is necessary to think about how true it is for your own private
              and confidential documents to circulate and spread all over the
              world's servers.
              <br />
              You also have to act with the limits and packages they set. With
              Ambar, you can host your server wherever you want and keep
              control. Moreover, these global cloud solutions do not have any
              disadvantages in terms of use, as well as the strengths of
              Dropbox, Google Drive and WeTransfer. In this way, you also have a
              better product that you can do every job.
            </p>
          </div>
          <img
            class="rimg"
            src="../assets/ambar/Ambar-Resim1.jpg"
            style="width: 100%; margin-top: 35px"
          />
          <div
            style="margin-top: 35px; padding-top: 100px; padding-bottom: 100px"
          ></div>
        </div>
      </div>
    </div>
  </main>
  <enFooter />
  <a
    href="#"
    class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i
  ></a>
  <div id="preloader" />
</template>

<script>
import router from "../router";
export default {
  name: "Ambar",
  components: {
    enHeader,
    enFooter,
    TopBar,
  },
  data: function () {
    return {
      loader: "",
    };
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
    );
    document.body.appendChild(plugin);
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.min.js"
    );
    document.body.appendChild(plugin2);
  },
  methods: {
    navigate() {
      router.push({ name: "Ambar" });
    },
  },
};

import enHeader from "./enHeader";
import TopBar from "./topbar/TopBar";
import enFooter from "./enFooter";
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css";
@import "~boxicons/css/boxicons.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "../styles/style.css";
ul {
  text-align: left;
}
</style>
