<template>
  <section id="services" class="services">
    <div class="container">
      <div class="section-title">
        <span>Our membershıps</span>
        <h2>Our membershıps</h2>
      </div>

      <div class="row">
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div><img src="@/assets/siber_kume.png" /></div>
            <h4><a href="">Turkish Cyber Security Cluster</a></h4>
            <p>
              The Turkish Cyber Security Cluster is a platform established for
              the purpose of developing the cyber security ecosystem in our
              country in line with the main goal of Turkey, which produces
              technology in the field of cyber security and can compete with the
              world, the foundations of which were laid under the leadership of
              the Presidency of Defense Industries and with the contributions of
              all relevant public institutions/organizations, private sector and
              academia representatives.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div class="icon-box">
            <div><img src="@/assets/bgdd.png" /></div>
            <h4><a href="">Information Security Association</a></h4>
            <p>
              To increase the level of knowledge and awareness in all segments
              of the society, especially its members, on Information Security
              and its sub-element, Cyber Security, to monitor technological
              developments on this subject, to contribute to the development of
              national technologies; was established to raise awareness about
              risks at individual, institutional and national level.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div class="icon-box">
            <div><img src="@/assets/sasad.png" /></div>
            <h4><a href="">SASAD</a></h4>
            <p>
              The Defense and Aviation Industry Manufacturers Association, whose
              short name is SaSaD, was established in Ankara in 1990 with the
              suggestion and support of the Ministry of National Defense. At the
              beginning of 2012, manufacturers in the field of civil aviation
              and space were also included in the working area of the
              association and the name of the association was changed to DEFENSE
              AND AVIATION INDUSTRY MANUFACTURERS ASSOCIATION.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "enOurRegisters",
};
</script>
