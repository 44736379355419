<template>
  <TopBar />

  <Header />

  <Hero />

  <main id="main">
    <!-- <Idef /> -->
    <Products />
    <Cta />
    <About />
    <OurRegisters />
    <OurCerts />
    <WhyUs />
    <Clients />
    <Contact />
  </main>

  <Footer />

  <a
    href="#"
    class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i
  ></a>
  <div id="preloader" />
</template>

<script>
import router from "../router";
export default {
  components: {
    OurRegisters,
    OurCerts,
    Products,
    Footer,
    Contact,
    Cta,
    Clients,
    WhyUs,
    About,
    Header,
    Hero,
    TopBar,
    // Idef,
  },
  data: function () {
    return {
      loader: "",
    };
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
    );
    document.body.appendChild(plugin);
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.min.js"
    );
    document.body.appendChild(plugin2);
  },
  methods: {
    btnClick() {},
    navigate() {
      router.push({ name: "Page1" });
    },
  },
};

import Header from "./Header";
import TopBar from "./topbar/TopBar";
import Hero from "./content/Hero";
import About from "./content/About";
import WhyUs from "./content/WhyUs";
import Clients from "./content/Clients";
import Cta from "./content/Cta";
import Contact from "./content/Contact";
import Footer from "./Footer";
import Products from "./content/Products";
import OurCerts from "./content/OurCerts";
import OurRegisters from "./content/OurRegisters";
import Idef from "./content/Idef";
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css";
@import "~boxicons/css/boxicons.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "../styles/style.css";
</style>
