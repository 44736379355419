<template>
  <section id="pricing" class="pricing">
    <div class="container">
      <div class="section-title">
        <span>Products</span>
        <h2>Products</h2>
        <p>
          Our products developed for the governance and security of your
          organization
        </p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="150">
          <div class="box">
            <!-- <h4><sup>$</sup>0<span> / month</span></h4> -->
            <img src="@/assets/aksiyon.png" width="100" height="100" />
            <h3>Aksiyon</h3>
            <h5>Internal Governance Software</h5>
            <ul>
              <li><i class="bi bi-check-circle" />Action/task management</li>
              <li><i class="bi bi-check-circle" />Project management</li>
              <li><i class="bi bi-check-circle" />Resource management</li>
              <li><i class="bi bi-check-circle" />Internal sharing</li>
              <li><i class="bi bi-check-circle" />Customer management</li>
              <li>
                <i class="bi bi-check-circle" />Request and support management
              </li>
              <li><i class="bi bi-check-circle" />Event calendar</li>
              <li><i class="bi bi-check-circle" />Leave management</li>
              <li>
                <i class="bi bi-check-circle" />Income and expense management
              </li>
              <li><i class="bi bi-check-circle" />Offer management</li>
              <li>
                <i class="bi bi-check-circle" />Notification and messaging
              </li>
              <li><i class="bi bi-check-circle" />Business notes</li>
            </ul>
            <p><strong>You can download our brochure below:</strong></p>
            <button class="" @click="onClick()">
              <i class="info-box bx bx-download"></i>
              Brochure
            </button>
            <div class="btn-wrap">
              <a href="#contact" class="btn-buy">Contact</a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 mt-4 mt-md-0" data-aos="zoom-in">
          <div class="box">
            <img src="@/svg/siper.svg" width="100" height="100" />
            <h3>Siper</h3>
            <h5>Internal Secure Communication Infrastructure</h5>
            <ul>
              <li><i class="bi bi-check-circle" />Works on any platform</li>
              <li>
                <i class="bi bi-check-circle" />Standard Messaging (Text,
                Contact, Picture, Audio, Video, Location, File Sharing)
              </li>
              <li>
                <i class="bi bi-check-circle" />Integration into the corporate
                directory
              </li>
              <li>
                <i class="bi bi-check-circle" />Installation on
                enterprise-specific servers
              </li>
              <li>
                <i class="bi bi-check-circle" />Communication between different
                installations
              </li>
              <li>
                <i class="bi bi-check-circle" />Securely archiving messages
              </li>
              <li><i class="bi bi-check-circle" />Compatibility with Pardus</li>
              <li>
                <i class="bi bi-check-circle" />Compliance with Enterprise
                Firewalls
              </li>
              <li><i class="bi bi-check-circle" />Multiple authentication</li>
              <li>
                <i class="bi bi-check-circle" />High-capacity audio and video
                conferencing
              </li>
              <li>
                <i class="bi bi-check-circle" />Smart group and distribution
                lists
              </li>
              <li>
                <i class="bi bi-check-circle" />Corporate group management
              </li>
            </ul>
            <p><strong>You can download our brochure below:</strong></p>
            <button class="" @click="onClick2()">
              <i class="info-box bx bx-download"></i>
              Brochure
            </button>
            <div class="btn-wrap">
              <a href="#contact" class="btn-buy">Contact</a>
            </div>
            <p>
              <b>For detailed information: </b><br />
              <a
                href="https://siperiletisim.com"
                target="blank"
                title="https://siperiletisim.com"
                >www.siperiletisim.com</a
              >
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 mt-4 mt-lg-0"
          data-aos="zoom-in"
          data-aos-delay="150"
        >
          <div class="box">
            <img src="@/assets/ambar.webp" width="100" height="100" />
            <h3>Ambar</h3>
            <h5>A secure repository for your documents</h5>
            <ul>
              <li><i class="bi bi-check-circle" />Works on any platform</li>
              <li>
                <i class="bi bi-check-circle" />You can keep all your documents
                confidentially and transfer them securely with end-to-end
                encryption methods
              </li>
              <li>
                <i class="bi bi-check-circle" />You do not have to use OneDrive,
                Dropbox and Google Drive applications that are not compatible
                with privacy policies
              </li>
              <li>
                <i class="bi bi-check-circle" />Works securely on your own
                servers
              </li>
              <li>
                <i class="bi bi-check-circle" />You can safely send and receive
                large files without using other privacy-free file transfer apps.
              </li>
              <li>
                <i class="bi bi-check-circle" />You can access your documents
                from anywhere
              </li>
              <li>
                <i class="bi bi-check-circle" />In addition to sharing the
                document within the organization, you can also share it with
                external stakeholders
              </li>
              <li>
                <i class="bi bi-check-circle" />Collaborate on Office documents
                on the web
              </li>
            </ul>
            <p><strong>You can download our brochure below:</strong></p>
            <button class="" @click="onClick3()">
              <i class="info-box bx bx-download"></i>
              Brochure
            </button>
            <div class="btn-wrap">
              <a href="#contact" class="btn-buy">Contact</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "enProducts",
  components: {},
  methods: {
    onClick() {
      axios({
        url: "/enaks.pdf",
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "enaks.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    onClick2() {
      axios({
        url: "/ensiper.pdf",
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "ensiper.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    onClick3() {
      axios({
        url: "/enambar.pdf",
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "enambar.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
};
</script>
<style scoped>
button {
  transition: 0.4s;
  display: inline-block;
  max-width: 200px;
  width: 147px;
  background-color: red;
  color: white;
  border: 0;
}
</style>
