<template>
  <section id="services" class="services">
    <div class="container">
      <div class="section-title">
        <span>Sertifikalarımız</span>
        <h2>Sertifikalarımız</h2>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up">
          <div class="icon-box">
            <div><img src="@/assets/iso.png" /></div>
            <h4><a>ISO 27001</a></h4>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="section-title">
        <span>Belgelerimiz</span>
        <h2>Belgelerimiz</h2>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up">
          <div class="icon-box">
            <div><img style="width: 100%" src="@/assets/tgb1.jpeg" /></div>
            <h4><a>TESİS GÜVENLİK BELGESİ (MİLLİ)</a></h4>
          </div>
        </div>
        <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up">
          <div class="icon-box">
            <div><img style="width: 100%" src="@/assets/tgb2.jpeg" /></div>
            <h4><a>TESİS GÜVENLİK BELGESİ (NATO)</a></h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurCerts",
};
</script>
