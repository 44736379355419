<template>
  <!-- ======= Cta Section ======= -->
  <section id="cta" class="cta">
    <div class="container" data-aos="zoom-in">
      <div class="text-center">
        <p>
          As Açık Deniz Bilişim, we can't wait for you to experience our
          applications that we have developed for institutions. If your
          institution needs any of the products we have developed, do not
          hesitate to contact us.
        </p>
        <a class="cta-btn" href="#">Contact Now</a>
      </div>
    </div>
  </section>
  <!-- End Cta Section -->
</template>

<script>
export default {
  name: "enCta",
};
</script>
