<template>
  <section id="pricing" class="pricing">
    <div class="container">
      <div class="section-title">
        <span>Ürünler</span>
        <h2>Ürünler</h2>
        <p>
          Kurumunuzun yönetişimi ve güvenliği için geliştirdiğimiz ürünlerimiz
        </p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="150">
          <div class="box">
            <!-- <h4><sup>$</sup>0<span> / month</span></h4> -->
            <img src="@/assets/aksiyon.png" width="100" height="100" />
            <h3>Aksiyon</h3>
            <h5>Kurum İçi Yönetişim Yazılımı</h5>
            <ul>
              <li><i class="bi bi-check-circle" />Aksiyon/Görev yönetimi</li>
              <li><i class="bi bi-check-circle" />Proje yönetimi</li>
              <li><i class="bi bi-check-circle" />Kaynak yönetimi</li>
              <li><i class="bi bi-check-circle" />Kurum içi paylaşım</li>
              <li><i class="bi bi-check-circle" />Müşteri yönetimi</li>
              <li><i class="bi bi-check-circle" />Talep ve destek yönetimi</li>
              <li><i class="bi bi-check-circle" />Etkinlik takvimi</li>
              <li><i class="bi bi-check-circle" />İzin yönetimi</li>
              <li><i class="bi bi-check-circle" />Gelir ve gider yönetimi</li>
              <li><i class="bi bi-check-circle" />Teklif yönetimi</li>
              <li><i class="bi bi-check-circle" />Bildirim ve mesajlaşma</li>
              <li><i class="bi bi-check-circle" />İş notları</li>
            </ul>
            <p><strong>Broşürümüzü aşağıdan indirebilirsiniz:</strong></p>
            <button class="" @click="onClick()">
              <i class="info-box bx bx-download"></i>
              Broşür
            </button>
            <div class="btn-wrap">
              <a href="#contact" class="btn-buy">İletişime Geç</a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 mt-4 mt-md-0" data-aos="zoom-in">
          <div class="box">
            <img src="@/svg/siper.svg" width="100" height="100" />
            <h3>Siper</h3>
            <h5>Kurum İçi Güvenli İletişim Altyapısı</h5>
            <ul>
              <li>
                <i class="bi bi-check-circle" />Her platformda çalışabilme
              </li>
              <li>
                <i class="bi bi-check-circle" />Standart Mesajlaşma (Yazı, Kişi,
                Resim, Ses, Video, Konum, Dosya Paylaşımı)
              </li>
              <li>
                <i class="bi bi-check-circle" />Kurum rehberine entegrasyon
              </li>
              <li>
                <i class="bi bi-check-circle" />Kuruma özel sunuculara kurulum
              </li>
              <li>
                <i class="bi bi-check-circle" />Farklı kurulumlar arasında
                iletişim
              </li>
              <li>
                <i class="bi bi-check-circle" />Mesajları güvenle arşivleme
              </li>
              <li><i class="bi bi-check-circle" />Pardus’a uyumluluk</li>
              <li>
                <i class="bi bi-check-circle" />Kurum Firewall’larına uyum
              </li>
              <li><i class="bi bi-check-circle" />Çoklu kimlik doğrulama</li>
              <li>
                <i class="bi bi-check-circle" />Yüksek kapasiteli sesli ve
                videolu konferans
              </li>
              <li>
                <i class="bi bi-check-circle" />Akıllı grup ve dağıtım listeleri
              </li>
              <li><i class="bi bi-check-circle" />Kurumsal grup yönetimi</li>
            </ul>
            <p><strong>Broşürümüzü aşağıdan indirebilirsiniz:</strong></p>
            <button class="" @click="onClick2()">
              <i class="info-box bx bx-download"></i>
              Broşür
            </button>
            <div class="btn-wrap">
              <a href="#contact" class="btn-buy">İletişime Geç</a>
            </div>
            <p>
              <b>Detaylı bilgi için: </b><br />
              <a
                href="https://siperiletisim.com"
                target="blank"
                title="https://siperiletisim.com"
                >www.siperiletisim.com</a
              >
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 mt-4 mt-lg-0"
          data-aos="zoom-in"
          data-aos-delay="150"
        >
          <div class="box">
            <img src="@/assets/ambar.webp" width="100" height="100" />
            <h3>Ambar</h3>
            <h5>Dokümanlarınız için güvenli bir depo</h5>
            <ul>
              <li>
                <i class="bi bi-check-circle" />Her platformda çalışabilme
              </li>
              <li>
                <i class="bi bi-check-circle" />Bütün dokümanlarınızı gizlilik
                içerisinde saklar ve uçtan uca şifreleme yöntemleri ile güvenle
                aktarabilirsiniz
              </li>
              <li>
                <i class="bi bi-check-circle" />Gizlilik politikaları ile uyumlu
                olmayan OneDrive, Dropbox ve Google Drive uygulamalarını
                kullanmak durumunda kalmazsınız
              </li>
              <li>
                <i class="bi bi-check-circle" />Kendi sunucularınızda güvenli
                olarak çalışırsınız
              </li>
              <li>
                <i class="bi bi-check-circle" />Büyük dosyalarınızı, gizlilik
                içermeyen diğer dosya transfer uygulamalarını kullanmadan
                emniyetli olarak gönderip alabilirsiniz
              </li>
              <li>
                <i class="bi bi-check-circle" />Dokümanlarınıza her yerden
                erişebilirsiniz
              </li>
              <li>
                <i class="bi bi-check-circle" />Dokümanı kurum içinde paylaşımın
                yanı sıra, dış paydaşlar ile de paylaşabilirsiniz
              </li>
              <li>
                <i class="bi bi-check-circle" />Office dokümanlarında, web
                üzerinden ortak çalışma yapabilirsiniz
              </li>
            </ul>
            <p><strong>Broşürümüzü aşağıdan indirebilirsiniz:</strong></p>
            <button class="" @click="onClick3()">
              <i class="info-box bx bx-download"></i>
              Broşür
            </button>
            <div class="btn-wrap">
              <a href="#contact" class="btn-buy">İletişime Geç</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "Products",
  components: {},
  methods: {
    onClick() {
      axios({
        url: "/akstr.pdf",
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "akstr.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    onClick2() {
      axios({
        url: "/sipertr.pdf",
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "sipertr.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    onClick3() {
      axios({
        url: "/ambartr.pdf",
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "ambartr.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
};
</script>
<style scoped>
button {
  transition: 0.4s;
  display: inline-block;
  max-width: 200px;
  width: 185px;
  background-color: red;
  color: white;
  border: 0;
}
</style>
