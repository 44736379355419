import { createRouter, createWebHistory } from "vue-router";

import Home from "../components/Home.vue";
import Siper from "../components/Siper.vue";
import Aksiyon from "../components/Aksiyon.vue";
import Ambar from "../components/Ambar.vue";

import enHome from "../components/enHome.vue";
import enAksiyon from "../components/enAksiyon.vue";
import enAmbar from "../components/enAmbar.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/siper",
    name: "Siper",
    component: Siper,
  },
  {
    path: "/aksiyon",
    name: "Aksiyon",
    component: Aksiyon,
  },
  {
    path: "/ambar",
    name: "Ambar",
    component: Ambar,
  },
  {
    path: "/enhome",
    name: "enHome",
    component: enHome,
  },
  {
    path: "/enaksiyon",
    name: "enAksiyon",
    component: enAksiyon,
  },
  {
    path: "/enambar",
    name: "enAmbar",
    component: enAmbar,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
