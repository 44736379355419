<template>
  <!-- ======= Clients Section ======= -->
  <section id="clients" class="clients">
    <div class="container" data-aos="zoom-in">
      <div class="row d-flex align-items-center">
        <div class="col-lg-3 col-md-4 col-6">
          <img src="@/assets/clients/turkcell.png" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-3 col-md-4 col-6">
          <img src="@/assets/clients/hurriyet.png" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-3 col-md-4 col-6">
          <img src="@/assets/clients/sbm_logo.png" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-3 col-md-4 col-6">
          <img src="@/assets/clients/tikle.png" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>
  <!-- End Clients Section -->
</template>

<script>
export default {
  name: "Clients",
};
</script>

<style scoped></style>
