<template>
  <TopBar />
  <Header />

  <Hero />

  <main id="main">
    <div style="position: relative">
      <img
        src="../assets/ambar/Ambar-Brosur-On-Small.jpg"
        style="
          background-size: cover;
          background-position: center center;
          width: 100%;
          height: 100%;
        "
      />
      <div
        style="
          position: absolute;
          font-size: 46px;
          color: rgb(12, 176, 243);
          text-align: center;
          width: 80%;
          top: 30%;
          left: 10%;
        "
      >
        <img src="../assets/ambar/Ambar-Logo-1.png" style="width: 60%" />
        <h1>DÖKÜMANLARINIZ İÇİN GÜVENLİ BİR DEPO</h1>
      </div>
    </div>
    <div class="row" style="height: 1000px; position: relative">
      <div class="col-sm-6" style="padding-top: 100px">
        <p>
          <span
            style="
              top: 80px;
              color: #5f727f;
              display: inline-block;
              max-width: 600px;
              margin: 0 auto;
            "
          >
            <strong>Ambar</strong> ile dökümanlarınıza her yerden erişin<br />
            (Google Drive ya da Dropbox yerine kendi sunucularınızda çalışan bir
            çözüm) Dökümanı dışarı ile paylaşma, ekip içi paylaşımlar,
            yetkilendirmeler, sürüm kontrolü ve yorumlarla çalışmalarınızı
            zenginleştirin.
          </span>
        </p>
        <h5>Her Cihazda Çalışır</h5>
        <ul>
          <li>
            Kolay ve modern bir web arayüzü, masaüstü (windows,mac,linux) ve
            mobil uygulamaları,
          </li>
          <li>
            Senkronizasyon özelliği her cihazda aynı dökümanlara kolay ve hızlı
            bir şekilde erişme imkanı sağlamaktadır.
          </li>
        </ul>
        <h5>Üst Düzey Güvenlik</h5>
        <ul>
          <li>
            Uçtan uca şifreleme yöntemleri ile dökümanlarınızı güvenli şekilde
            yönetin,
          </li>
          <li>
            Dökümanlar kendi sunucularınızda barınsın ve dışarında ve içeriden
            erişimleri kolay şekilde denetleyin
          </li>
        </ul>
        <h5>Entegrasyonlar</h5>
        <ul>
          <li>
            Ftp, Windows Network, SharePoint, NFS gibi birçok sistem ile
            entegrasyon yapabilir,
          </li>
          <li>
            Kolay konfigurasyon yaparak az maliyet ile bu süreçleri kolay, hızlı
            ve güvenilir bir şekilde yönetebilirsiniz.
          </li>
        </ul>
      </div>
      <div class="col-sm-6" style="padding-top: 100px">
        <img
          src="../assets/ambar/Ambar-Brosur-On-Small.jpg"
          style="width: 80%"
        />
      </div>
    </div>

    <div style="background-color: #cef0fd">
      <div
        class="container row"
        style="
          margin-right: auto;
          margin-left: auto;
          padding-left: 21px;
          padding-right: 21px;
        "
      >
        <h1 style="text-align: center; margin-top: 70px">
          <span class="light" style="color: #46485c">NEDEN AMBAR?<br /> </span>
        </h1>
        <div class="col-sm-6" style="margin-right: 0; margin-top: 35px">
          <div style="margin-top: 35px; padding-bottom: 100px">
            <p>
              Çalışanlar dökümanlara kolay erişebilmeli ve diğer çalışanlar ile
              dökümanları kolayca paylaşabilmelidir. Hatta dışarıya paylaşmak ve
              bunu güvenli şekilde yapmak bir işletmenin bilgilerini koruması
              açısından çok önemlidir. Ambar ile dökümanlarınızı hem güvenli
              şekilde saklar hem de hem iç hemde dışarıdaki müşterileriniz,
              tedarikçileriniz, ortaklarınız ve kişiler ile kolay şekilde
              paylaşırsınız. Paylaştığınız ve yetkisi olan kişiler bu
              dökümanlara erişebilir. Kendi sunucularınızda çalıştırabilirsiniz
              veya bulut sunucu hizmetimizden faydalanarak rahat bir şekilde
              kullanabilirsiniz.
            </p>
          </div>
          <img
            class="rimg"
            src="../assets/ambar/4-31.jpg"
            style="width: 100%; margin-top: 35px"
          />
          <div
            style="margin-top: 35px; padding-top: 100px; padding-bottom: 100px"
          >
            <ul>
              <li>
                Dropbox veya Google Drive gibi çalışır, ancak limitleri siz
                belirlersiniz.
              </li>
              <li>
                Wetransfer gibi süreli ve geçici havuzlar oluşturabilirsiniz.
              </li>
              <li>Belli gruplara kotalar verebilirsiniz.</li>
              <li>
                Desktop uygulaması ile bilgisayarınızdan kolay çalışma imkanı
              </li>
              <li>
                Web arayüzünden admin tüm ayarlamaları kolay şekilde yapabilir.
              </li>
              <li>
                Hem içerideki çalışanlar hem dışarıdaki çalışanlara güvenli
                paylaşım yapabilirsiniz.
              </li>
              <li>Versiyon tutarak döküman geçmişini görebilirsiniz.</li>
              <li>
                Otomatik yedekleme ile dökümanlarınızı yedekleyebilirsiniz.
              </li>
              <li>
                Office dökümanlarında web üzerinden ortak çalışma yapılabilir.
              </li>
            </ul>
          </div>
          <img
            class="rimg"
            src="../assets/ambar/7-27.jpg"
            style="width: 100%; margin-top: 35px"
          />
        </div>

        <div class="col-sm-6" style="">
          <img
            class="rimg"
            src="../assets/ambar/5-30.jpg"
            style="width: 100%; margin-top: 35px"
          />
          <div
            style="margin-top: 35px; padding-top: 100px; padding-bottom: 100px"
          >
            <h6>HERKESE AÇIK BULUT ÇÖZÜMLERİ YETERLİ DEĞİL Mİ?</h6>
            <p>
              Birçok kurum Dropbox , Google Drive gibi çözümler kullanıyor ve bu
              çözümler gizlilik politikaları ile uyumlu değil. Kendi özel ve
              gizli dökümanlarınızın tüm dünya serverlarında dolaşması ve
              yayılması ne kadar doğru bunu düşünmek gerekiyor.
              <br />
              Ayrıca onların koyduğu limitler ve paketler ile hareket etmek
              zorundasınız. Ambar ile sunucunuzu istediğiniz yerde
              barındırabiliyor ve kontrolü elinizde tutabiliyorsunuz. Üstelik bu
              global bulut çözümlerinden kullanım olarak hiçbir eksisi olmadığı
              gibi Dropbox, Google Drive ve WeTransfer in güçlü yönlerini
              barındırıyor. Bu sayede her işi yapabileceğiniz daha iyi bir ürüne
              de sahip olmuş oluyorsunuz.
            </p>
          </div>
          <img
            class="rimg"
            src="../assets/ambar/Ambar-Resim1.jpg"
            style="width: 100%; margin-top: 35px"
          />
          <div
            style="margin-top: 35px; padding-top: 100px; padding-bottom: 100px"
          ></div>
        </div>
      </div>
    </div>
  </main>
  <Footer />
  <a
    href="#"
    class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i
  ></a>
  <div id="preloader" />
</template>

<script>
import router from "../router";
export default {
  name: "Ambar",
  components: {
    Header,
    Footer,
    TopBar,
  },
  data: function () {
    return {
      loader: "",
    };
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
    );
    document.body.appendChild(plugin);
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.min.js"
    );
    document.body.appendChild(plugin2);
  },
  methods: {
    navigate() {
      router.push({ name: "Ambar" });
    },
  },
};

import Header from "./Header";
import TopBar from "./topbar/TopBar";
import Footer from "./Footer";
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css";
@import "~boxicons/css/boxicons.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "../styles/style.css";
ul {
  text-align: left;
}
</style>
