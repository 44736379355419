<template>
  <header id="header" class="d-flex align-items-center fixed-top">
    <div class="container d-flex align-items-center justify-content-between">
      <!-- <h1 class="logo">
        <a href="index.html">Açık Deniz Bilişim</a>
      </h1>
      Uncomment below if you prefer to use an image logo -->
      <a href="/" class="logo">
        <img src="@/assets/logo.png" alt="" class="img-fluid" />
      </a>

      <div id="navbar" :class="'navbar ' + mobile">
        <ul>
          <NavItem to_ref="/" txt="Ana Sayfa" class="nav-link scrollto" />
          <NavItem
            to_ref="/#about"
            txt="Kurumsal"
            class="nav-link scrollto"
            @click="onItemClick"
          />
          <NavItem
            to_ref="/#why-us"
            txt="Çözümlerimiz"
            class="nav-link scrollto"
            @click="onItemClick"
          />
          <li class="dropdown">
            <a class="dropbtn nav-link">Ürünler</a>
            <div class="dropdown-content">
              <a href="https://siperiletisim.com" target="blank">Siper</a>
              <a href="/ambar">Ambar</a>
              <a href="/aksiyon">Aksiyon</a>
            </div>
          </li>
          <NavItem
            to_ref="/#contact"
            txt="İletişim"
            class="nav-link scrollto"
            @click="onItemClick"
          />
          <li>
            <a href="/enhome" class="nav-link"
              >English
              <img
                src="@/assets/globe.png"
                alt=""
                class="img-fluid"
                style="margin-left: 5px"
              />
            </a>
          </li>
        </ul>
        <i
          :class="'bi mobile-nav-toggle ' + x_button"
          @click="mobileNavToggle"
        ></i>
      </div>
    </div>
  </header>
</template>

<script>
import NavItem from "./nav/NavItem";

export default {
  name: "Header",
  data: function () {
    return {
      test: 0,
      mobile: "",
      x_button: "bi-list",
      windowWidth: window.innerWidth,
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },
  components: { NavItem },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    mobileNavToggle() {
      if (this.test === 0) {
        this.mobile = "navbar-mobile";
        this.x_button = "bi-x";

        this.test = 1;
      } else {
        this.mobile = "";
        this.x_button = "bi-list";

        this.test = 0;
      }
    },
    onItemClick() {
      if (this.windowWidth < 991) {
        if (this.test === 0) {
          this.mobile = "navbar-mobile";
          this.x_button = "bi-x";

          this.test = 1;
        } else {
          this.mobile = "";
          this.x_button = "bi-list";

          this.test = 0;
        }
      }
    },
  },
};
</script>

<style scoped>
/* Dropdown Button */
.dropbtn {
  /*  background-color: #04AA6D;
  color: white;*/
  padding: 16px;
  font-size: 16px;
  border: none;
}
/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
/* Change the background color of the dropdown button when the dropdown content is shown */
/*.dropdown:hover .dropbtn {background-color: #3e8e41;}*/
</style>
