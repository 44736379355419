<template>
  <!-- ======= About Section ======= -->
  <section ref="about" id="about" class="about">
    <div class="container">
      <div class="section-title">
        <span>Açık Deniz Bilişim</span>
        <h2>Açık Deniz Bilişim</h2>
      </div>
      <div class="row">
        <div class="" data-aos="fade-right">
          <p>
            Açık Deniz information technologies and Consulting Inc.; It was
            established in Uşak in 2008 and announced its name in 2011 with the
            "Turkish Messenger" application. The company, which went through a
            restructuring in 2013, was included in the Akçay Group of Companies.
            <br /><br />

            Açık Deniz A.Ş., which has started to carry out R&D activities in
            Istanbul since 2013; It has implemented mobile project applications
            such as Turkcell Fizy, Hürriyet E-Newspaper, Insurance Information
            Center (SBM), which are used extensively in our country.<br /><br />

            Açık Deniz A.Ş. has the "ISO-27001 Certificate", which certifies
            compliance with international information security management system
            standards. Since 2015, it has started to develop an in-house
            Encrypted Mobile Communication application called SİPER. Completing
            the developments in 2018, it successfully passed the security and
            penetration (leakage) tests of the product carried out by the UITSEC
            Test Firm.
            <br /><br />
            Açık Deniz A.Ş. developed a product called AMBAR, a secure file
            archiving and sharing system, in 2018. Thanks to this product, all
            files shared internally and externally from SİPER and other
            applications can be secured. <br /><br />

            In addition, thanks to the product called AKSİYON, which is the
            internal governance software of Açık Deniz A.Ş.; A management system
            has been developed for both SİPER and AMBAR, and a product that can
            manage all corporate affairs.<br /><br />

            Açık Deniz A.Ş. still continues to work on attack detection and
            prevention with artificial intelligence software. <br /><br />

            Açık Deniz A.Ş., and Havelsan A.Ş. A Business Partnership Agreement
            was signed in 2018. It is also a member of the Information Security
            Association, SaSaD and the Turkish Cyber Security Cluster.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="about">
    <div class="container">
      <div class="section-title">
        <span>Our vısıon</span>
        <h2>Our vısıon</h2>
      </div>

      <div class="row">
        <p>
          Our vision; To provide world-class special solutions to our customers
          and to open up to global markets by continuing our role in domestic
          and secure communication technologies with studies to be made on
          innovative technologies (artificial intelligence, internet of things,
          big data, blockchain and 5G).
        </p>
      </div>
    </div>
  </section>

  <section class="about">
    <div class="container">
      <div class="section-title">
        <span>Our Mıssıon</span>
        <h2>Our Mıssıon</h2>
      </div>
      <div class="row">
        <p>
          Our Mission; to specialize in communication and information security,
          to provide the best solution to our customers by using domestic and
          national software that we have developed and to provide an effective
          customer support service.
        </p>
      </div>
    </div>
  </section>
  <!-- End About Section -->
</template>

<script>
export default {
  name: "enAbout",
};
</script>

<style scoped></style>
