<template>
  <section id="services" class="services">
    <div class="container">
      <div class="section-title">
        <span>Üyeliklerimiz</span>
        <h2>Üyeliklerimiz</h2>
      </div>

      <div class="row">
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div><img src="@/assets/siber_kume.png" /></div>
            <h4><a href="">Türkiye Siber Güvenlik Kümelenmesi</a></h4>
            <p>
              Türkiye Siber Güvenlik Kümelenmesi, Savunma Sanayii Başkanlığı
              öncülüğünde ve ilgili tüm kamu kurum/kuruluşları, özel sektör ve
              akademi temsilcilerinin katkılarıyla temelleri atılan, siber
              güvenlik alanında teknoloji üreten ve dünya ile rekabet edebilen
              Türkiye ana hedefi doğrultusunda ülkemizde siber güvenlik
              ekosisteminin geliştirilmesi amacıyla kurulan bir platformdur.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div class="icon-box">
            <div><img src="@/assets/bgdd.png" /></div>
            <h4><a href="">Bilgi Güvenliği Derneği</a></h4>
            <p>
              Bilgi Güvenliği ve onun bir alt unsuru olan Siber Güvenlik
              konusunda başta üyeleri olmak üzere toplumun her kesiminde bilgi
              ve bilinç düzeyini arttırmak, bu konu ile ilgili teknolojik
              gelişmeleri izlemek, milli teknolojilerin geliştirilmesine katkı
              sağlamak; bireysel, kurumsal ve ulusal düzeydeki riskler konusuda
              farkındalık oluşturmak amacıyla kurulmuştur
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div class="icon-box">
            <div><img src="@/assets/sasad.png" /></div>
            <h4><a href="">SASAD</a></h4>
            <p>
              Kısa ismi SaSaD olan, Savunma ve Havacılık Sanayii İmalatçılar
              Derneği, Milli Savunma Bakanlığı'nın öneri ve desteği ile 1990
              yılında Ankara’da kuruldu. 2012 yılı başında sivil havacılık ve
              uzay alanındaki üreticiler de derneğin çalışma alanına alındı ve
              derneğin adı SAVUNMA VE HAVACILIK SANAYİİ İMALATÇILAR DERNEĞİ
              olarak değiştirildi.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurRegisters",
};
</script>
