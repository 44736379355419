<template>
  <!-- ======= Why Us Section ======= -->
  <section id="why-us" class="why-us">
    <div class="container">
      <div class="section-title">
        <span>Çözümlerimiz</span>
        <h2>Çözümlerimiz</h2>
      </div>

      <div class="row">
        <div class="col-lg-4" data-aos="fade-up">
          <div class="box">
            <span>01</span>
            <h4>IT Güvenliği</h4>
            <p>
              Özellikle kurumiçi haberleşme güvenliği ve veri güvenliği
              konusunda çalışmalar yapıyoruz. Sizlerin güvenlik konusundaki
              ihtiyaçlarınızı önce analiz edip daha sonra kurum içinde
              yaptığınız haberleşmelerin daha güvenli olması için neler yapmak
              lazım bunları çıkartıyoruz. SİPER ve AKSİYON ürünümüz sayesinde bu
              güvenliği en üst seviyeye taşıyoruz. Ayrıca genel bilgi işlem
              güvenliğiniz konusunda da danışmanlık ve destek veriyoruz.<br /><br />

              Güvenlik analizi ve süreçlerinizin incelenmesi ile önce mevcut
              durumunuz ortaya çıkarılıyor. Daha sonra ihtiyaçlar ve nasıl
              aksiyon alınacağı netleştirilerek çözümlerimizi sunuyoruz.<br /><br />

              İç ve dış denetimlere hazır olmak, verilerinizin güvenli olduğuna
              emin olmak her işletme için çok önemli. Bu konuda sizleri hazır
              hale getiriyoruz.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div class="box">
            <span>02</span>
            <h4>Danışmanlık</h4>
            <p>
              Özellikle veri güvenliği ve bilgi güvenliği yönetim sistemi (ISO
              27001) hazırlık aşamasında sizlere destek oluyor ve çözümler
              üretiyoruz.<br /><br />

              Güvenlik analizi, penetrasyon testleri, güvenli sunucu kurulumları
              nasıl olmalı bu konuda size destek oluyor ve danışmanlık
              yapıyoruz.<br /><br />

              Firmamızda IT Güvenik konusunda yetişmiş ve birçok kurumda bu
              sistemi uygulamış güvenlik uzmanları bulunuyor.<br /><br />

              İç ve dış denetimlere hazır olmak, verilerinizin güvenli olduğuna
              emin olmak her işletme için çok önemli. Bu konuda sizleri hazır
              hale getiriyoruz.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div class="box">
            <span>03</span>
            <h4>Yazılım Geliştirme</h4>
            <p>
              Mobil, desktop ve web tabanlı yazılımlar geliştiriyoruz. Seçkin
              ekibimiz, en son strateji ve tasarım düşüncelerini kurumsal
              düzeyde yazılım geliştirme ile köprü kurarak olağanüstü deneyimler
              üretme yolunda beceriyle kullanıyorlar. Ekiplerimiz aynı çatı
              altında birlikte çalışırlar. Sadece en iyi elemanları seçiyoruz ve
              ekibimize özerk olarak çalışmalarını sağlayacak kadar güveniyoruz.
              Bu şekilde özerk çalışma imkanı bulan ekibimizin tek sorumluluğu
              müşterilerimize olağanüstü sonuçlar vermektir.<br /><br />

              Geliştirdiğimiz 3 ana ürünümüz SİPER, AMBAR ve AKSİYON yazılımları
              ile kurumun tüm iş yönetimi , güvenli haberleşme ve güvenli
              döküman yönetimi ihtiyaçlarını çözüyorüz. Sizlerden gelebilecek ek
              talepleri de değerlendirerek yazılım çözümleri sunuyoruz.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Why Us Section -->
</template>

<script>
export default {
  name: "WhyUs",
};
</script>

<style scoped></style>
