<template>
  <!-- ======= About Section ======= -->
  <section ref="about" id="about" class="about">
    <div class="container">
      <div class="section-title">
        <span>Açık Deniz Bilişim</span>
        <h2>Açık Deniz Bilişim</h2>
      </div>
      <div class="row">
        <div class="" data-aos="fade-right">
          <p>
            Açık Deniz Bilişim ve Danışmanlık A.Ş.; 2008 yılında Uşak’ta
            kurulmuş olup, 2011’de “Türk Messenger” uygulamasıyla adını
            duyurmuştur. 2013 yılında yeniden yapılanmaya giden Firma, Akçay
            Şirketler Grubu’na dahil olmuştur.<br /><br />

            2013 yılından itibaren İstanbul’da Arge faaliyetlerini yürütmeye
            başlayan Açık Deniz A.Ş.; Turkcell Fizy, Hürriyet E-Gazete, Sigorta
            Bilgi Merkezi (SBM) gibi ülkemizde çok yoğun kullanılan mobil proje
            uygulamalarını gerçekleştirmiştir.<br /><br />

            Açık Deniz A.Ş., uluslararası bilgi güvenliği yönetim sistemi
            standartlarına uygunluğu belgeleyen “ISO-27001 Sertifikası”na
            sahiptir. 2015 yılından itibaren SİPER adlı Kurum içi Şifreli Mobil
            Haberleşme uygulamasını geliştirmeye başlamıştır. 2018 yılında
            geliştirmeleri tamamlayarak, UITSEC Test Firması tarafından
            gerçekleştirilen ürüne ait güvenlik ve penetrasyon (sızdırmazlık)
            testlerinden başarı ile geçmiştir.<br /><br />

            Açık Deniz A.Ş., 2018 yılında güvenli dosya arşivleme ve paylaşım
            sistemi olan AMBAR isimli ürünü geliştirmiştir. Bu ürün sayesinde,
            SİPER ve diğer uygulamalardan kurum içi ve kurum dışına paylaşılan
            tüm dosyalar güvenlik altına alınabilmektedir.<br /><br />

            Ayrıca, Açık Deniz A.Ş.’ye ait kurum içi yönetişim yazılımı olan
            AKSİYON isimli ürün sayesinde; hem SİPER, hem AMBAR için bir yönetim
            sistemi ve kuruma ait tüm işlerin yönetilebileceği bir ürün
            geliştirilmiştir.<br /><br />

            Açık Deniz A.Ş., halen yapay zeka yazılımı ile saldırı tespiti ve
            önleme çalışmalarına devam etmektedir.<br /><br />

            Açık Deniz A.Ş., ile Havelsan A.Ş. arasında 2018 yılında İş
            Ortaklığı Sözleşmesi imzalanmıştır. Ayrıca, Bilgi Güvenliği
            Derneği’ne, SaSaD’a ve Türkiye Siber Güvenlik Kümelenmesi’ne üyedir.
          </p>
          <!-- <ul>
            <li><i class="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
            <li><i class="bi bi-check-circle"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
            <li><i class="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
          </ul>
          <p>
            Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum
          </p> -->
        </div>
      </div>
    </div>
  </section>

  <section class="about">
    <div class="container">
      <div class="section-title">
        <span>Vizyonumuz</span>
        <h2>Vizyonumuz</h2>
      </div>

      <div class="row">
        <p>
          Vizyonumuz; yerli ve güvenli iletişim teknolojilerindeki rolümüzü,
          yenilikçi teknolojiler (yapay zeka, nesnelerin interneti, büyük veri,
          blok zinciri ve 5G) üzerinde yapılacak çalışmalar ile sürdürerek
          müşterilerimize dünya standartlarında özel çözümler sunmak ve küresel
          pazarlara açılmaktır.
        </p>
      </div>
    </div>
  </section>

  <section class="about">
    <div class="container">
      <div class="section-title">
        <span>Misyonumuz</span>
        <h2>Misyonumuz</h2>
      </div>
      <div class="row">
        <p>
          Misyonumuz; iletişim ve bilgi güvenliği konusunda uzmanlaşmak, kendi
          geliştirdiğimiz yerli ve milli yazılımları kullanarak müşterilerimize
          en iyi çözümü sunmak ve etkin bir müşteri destek hizmeti sağlamaktır.
        </p>
      </div>
    </div>
  </section>
  <!-- End About Section -->
</template>

<script>
export default {
  name: "About",
};
</script>

<style scoped></style>
