<template>
  <section id="hero" class="d-flex align-items-center">
    <div
      class="container position-relative"
      data-aos="fade-up"
      data-aos-delay="500"
    >
      <!-- <img src="@/assets/logo.png" /> -->
      <h1>Açık Deniz Bilişim</h1>
      <br />
      <br />
      <h2>Yerli ve Güvenli Bilişim Teknolojilerinde Öncü</h2>
    </div>
  </section>
  <!-- End Hero -->
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style scoped></style>
