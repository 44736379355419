<template>
  <!-- ======= Why Us Section ======= -->
  <section id="why-us" class="why-us">
    <div class="container">
      <div class="section-title">
        <span>Servıces</span>
        <h2>Servıces</h2>
      </div>

      <div class="row">
        <div class="col-lg-4" data-aos="fade-up">
          <div class="box">
            <span>01</span>
            <h4>IT Security</h4>
            <p>
              We especially work on internal communication security and data
              security. We analyze your security needs first and then find out
              what needs to be done to make your communications within the
              organization more secure. Thanks to our SİPER and AKSİYON product,
              we take this security to the highest level. We also provide
              consultancy and support on your general computing security.<br /><br />

              With the security analysis and the examination of your processes,
              your current situation is first revealed. Then, we present our
              solutions by clarifying the needs and how to take action.<br /><br />

              Being ready for internal and external audits and making sure your
              data is secure is very important for any business. In this regard,
              we make you ready.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div class="box">
            <span>02</span>
            <h4>Consulting</h4>
            <p>
              We support you and produce solutions especially during the
              preparation phase of data security and information security
              management system (ISO 27001).<br /><br />

              We support and advise you on how security analysis, penetration
              tests, secure server installations should be.<br /><br />

              There are security experts in our company who have been trained in
              IT Security and have implemented this system in many
              institutions.<br /><br />

              Being ready for internal and external audits and making sure your
              data is secure is very important for any business. In this regard,
              we make you ready.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div class="box">
            <span>03</span>
            <h4>Software Development</h4>
            <p>
              We develop mobile, desktop and web-based software. Our
              distinguished team skilfully use the latest strategy and design
              thinking to create exceptional experiences by bridging
              enterprise-grade software development. Our teams work together
              under the same roof. We select only the best staff and trust our
              team enough to allow them to work autonomously. The only
              responsibility of our team, which has the opportunity to work
              autonomously in this way, is to give our customers extraordinary
              results.<br /><br />

              We solve all the business management, secure communication and
              secure document management needs of the institution with our 3
              main products, SİPER, AMBAR and AKSİYON software. We offer
              software solutions by considering additional requests from you.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Why Us Section -->
</template>

<script>
export default {
  name: "enWhyUs",
};
</script>

<style scoped></style>
