<template>
  <TopBar />
  <Header />

  <Hero />

  <main id="main">
    <div style="position: relative">
      <img
        src="@/assets/siper/Siper-Brosur-Small.jpg"
        style="
          background-size: cover;
          background-position: center center;
          width: 100%;
          height: auto;
        "
      />
      <div
        style="
          position: absolute;
          top: 30%;
          left: 65%;
          width: 20%;
          height: auto;
        "
      >
        <img
          src="@/assets/siper/Siper-Logo.png"
          style="width: 100%; height: auto"
        />
        <p style="color: #ff6666">
          <br />
          KURUM İÇİ <br />GÜVENLİ <br />İLETİŞİM ALTYAPISI
        </p>
      </div>
    </div>

    <div style="position: relative; height: 900px">
      <img
        src="@/assets/siper/18-6.jpg"
        style="background-size: cover; background-position: center center"
      />

      <div class="row" style="position: absolute; top: 10%">
        <img
          src="@/assets/siper/mobile.png"
          class="col-md-6"
          style="width: 50%; height: auto; max-width: 600px; margin: auto"
        />
        <p class="col-md-6" style="color: #ffcccc; margin: auto">
          SİPER SAYESİNDE ARTIK VAROLAN İLETİŞİM UYGULAMALARINA İHTİYAÇ
          KALMAYACAK DAHA GELİŞMİŞ DAHA ÇOK ÖZELLEŞTİREBİLECEĞİNİZ, GÜVENLİ VE
          ARŞİVLENEBİLİR, KENDİNİZE AİT BİR GÜVENLİ İLETİŞİM ALTYAPISINA SAHİP
          OLURSUNUZ.
        </p>
      </div>
    </div>
    <div style="position: relative; text-align: center">
      <img
        src="@/assets/siper/4-26.jpg"
        style="
          background-size: cover;
          background-position: center center;
          height: 100%;
        "
      />
      <div
        style="
          position: absolute;
          width: 80%;
          right: 10%;
          color: rgb(255, 255, 255);
          top: 75px;
          margin: auto;
        "
      >
        <h3>NEDEN SİPER ?</h3>
        Herkese açık iletişim uygulamalarında kontrol sizde değildir. Güvenlik
        ve arşiv açısından size ait olmayan bir üründe elinizden birşey gelmez.
        Ancak size ait böyle bir iletişim uygulaması ve ek geliştirilebilir
        modülleri ile çok avantajlı ve işletmenin vazgeçilmez bir ürünü
        olacaktır. Size özel geliştirmeler ve özelleştirmeler yapılabilir. Nasıl
        email gerekli ise artık günümüzde mesajlaşma, sesli ve videolu
        görüşmelerde iletişim için vazgeçilmezdir. Email altyapınızı kendi
        sunucularınızda çalıştırmak, güvenlik altına almak, arşivlemek ne kadar
        gerekli ise, anlık mesajlaşma ve iletişimde aynı şekilde güvenli ve
        hızlı olmalıdır. İş için görüşmeler yapıyorsanız bunların hem güvenli
        olmasını hem de güvenli şekilde arşivlenmesini isteyebilirsiniz. Siper
        esnek seçenekleri ile ister arşivlenebilen ister ise arşiv olmadan
        çalışabilen bir yapıya sahiptir. Sadece belli cihazlarda değil tüm
        android, ios ve desktop (Windows, Mac, Linux) üzerinde çalışabilir. Hem
        network , hem yazılımsal katmanlarda güvenlik seçenekleri mevcuttur.
        <br />
        <img
          src="@/assets/siper/3-6.png"
          style="width: 80%; margin-top: 50px"
        />
      </div>
    </div>

    <div style="position: relative">
      <img
        src="@/assets/siper/7-23.jpg"
        style="
          background-size: cover;
          background-position: center center;
          height: 100%;
        "
      />
      <div
        style="
          position: absolute;
          min-width: 80%;
          color: rgb(255, 255, 255);
          top: 100px;
        "
      >
        <h3>SİPER GÜVENLİ Mİ?</h3>
        <p>
          Siper’i kullanan kullanıcılar özel bir şekilde tanımlanır ve sisteme
          giriş yaparlar. Özel bir anahtar kimlik için üretilirken aynı zamanda
          her mesaj uçtan uca şifrelenir. Ssl , firewall gibi güvenlik
          katmanları haricinde özel şifreleme yöntemleri kullanılmaktadır. Her
          mesaj ayrı şekilde özel bir yöntem ile şifrelenir. Bir mesajın şifresi
          kırıldı diyelim (ki bu çok zordur) diğer mesajların deşifre edilmesi
          bir o kadar zordur. Önceki ve sonraki mesajların deşifre edileceği
          anlamına gelmez. Koruma sürekli her mesajda yeniden şekillendirilir.
          Ayrıca sadece yazılım olarak mesajlaşma değil bir katman olarak siper
          saldırı tespiti ve önleme görevini de yerine getirir. Yani amacı
          sadece şifrelemek değildir. Sunucuyu da güvenlik çemberi içinde tutar.
          SİPER’İN ÜSTÜN ÖZELLİKLERİ Kendi sunucularınızda çalışabilme imkanı,
          Uçtan uca şifreleme ile her mesajın ayrı şifrelendiği güvenilir bir
          iletişim altyapısı, Dilerseniz tüm görüşmeleri güvenli şekilde
          arşiveleme imkanı, LDAP ve AD entegrasyonları ile kurumunuz
          kullanıcıları için hesap yönetimi, Offline özelliği kapatabilme imkanı
          bu sayede kurumdan ayrılan kullanıcı erişimini kısıtlayabilme, Sadece
          kurum içi değil kurum dışındaki paydaşlara da kısıtlı yetkiler ile
          giriş imkanı, Belirli filtreler ile dinamik ve kendini yöneten gruplar
          ve dağıtım listeleri oluşturabilme Kaliteli sesli ve videolu
          görüşmeler yapabilme ve yüksek kullanıcı sayılarına ulaşabilen
          konferans özelliği Altyapımızı başka uygulamalarda kullanabilme Yapay
          zeka ile saldırı ve anormallik tespiti Multi factor authentication
          (Çoklu kimlik doğrulama)
        </p>
        <img src="@/assets/siper/8-3.png" style="width: 80%" />
      </div>
    </div>
  </main>
  <Footer />
  <a
    href="#"
    class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i
  ></a>
  <div id="preloader" />
</template>

<script>
import router from "../router";
export default {
  name: "Siper",
  components: {
    Header,
    Footer,
    TopBar,
  },

  data: function () {
    return {
      loader: "",
    };
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
    );
    document.body.appendChild(plugin);
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.min.js"
    );
    document.body.appendChild(plugin2);
  },
  methods: {
    navigate() {
      router.push({ name: "Siper" });
    },
  },
};

import Header from "./Header";
import TopBar from "./topbar/TopBar";
import Footer from "./Footer";
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css";
@import "~boxicons/css/boxicons.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "../styles/style.css";
.container {
  position: relative;
  text-align: center;
}
#img2 {
  position: absolute;
  left: 50px;
  top: 50px;
}
.left {
  position: absolute;
  top: 80px;
  left: 50px;
}
.background {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}
</style>
