<template>
  <TopBar />
  <enHeader />

  <Hero />

  <main id="main">
    <div style="position: relative">
      <img
        src="../assets/aksiyon/Aksiyon-Brosur-Resim-Small.jpg"
        style="
          background-size: cover;
          background-position: center center;
          width: 100%;
          height: 100%;
        "
      />
      <div
        style="
          position: absolute;
          font-size: 4rem;
          color: #00bcd4;
          width: 80%;
          top: 20%;
          left: 10%;
        "
      >
        <img
          src="../assets/aksiyon/aksiyon_logo_buyuk_mavi.png"
          style="width: 60%"
        />
        <h1>IN-house <br />GOVERNANCE SOFTWARE</h1>
      </div>
    </div>
    <div class="row" style="height: 800px; position: relative">
      <div class="col-sm-6" style="padding-top: 100px">
        <p>
          <span
            style="
              top: 80px;
              color: #5f727f;
              display: inline-block;
              max-width: 600px;
              margin: 0 auto;
            "
          >
            <strong>Action</strong> is a cloud technology-based
            <strong>governance, planning</strong> and
            <strong>sharing</strong> system that uses a variety of techniques
            such as backtracking, decision trees, search mechanisms that provide
            extensive data processing, learning, inference, decision making and
            detection tools.
          </span>
        </p>
        <h5><span style="color: #00bcd4">OUR GAINS</span></h5>
        <ul>
          <li>High Profitability</li>
          <li>Efficient Business Management and Control</li>
          <li>Competition power Increase</li>
          <li>Corporate Trust</li>
          <li>Corporate Knowledge Increase</li>
          <li>Goal Oriented Business Management</li>
        </ul>
      </div>
      <div class="col-sm-6" style="padding-top: 100px">
        <img src="../assets/aksiyon/Aksiyon-Resim.jpg" style="width: 80%" />
      </div>
    </div>

    <div style="background-color: #99d6ff">
      <div
        class="container row"
        style="
          margin-right: auto;
          margin-left: auto;
          padding-left: 21px;
          padding-right: 21px;
        "
      >
        <h1 style="text-align: center; margin-top: 70px">
          <span class="light" style="color: #46485c"
            >WHAT IS AKSIYON?<br />
          </span>
        </h1>
        <div class="col-sm-6" style="margin-right: 0; margin-top: 35px">
          <div style="margin-top: 35px; padding-bottom: 100px">
            <p>
              With the AKSIYON software, the assignments of the employees of the
              institution within the scope of the activities and activities
              created and the information they need about these assignments,
              physical equipment, and informing them about the relevant
              documents, monitoring the time and work planning of the activity,
              seeing the realization of the tasks and making a status
              notification about their assignments and making a notification
              with these notifications. Adding various related multimedia and
              other documents is provided.
            </p>
          </div>
          <img
            class="rimg"
            src="../assets/aksiyon/4-31.jpg"
            style="width: 100%; margin-top: 35px"
          />
          <div
            style="margin-top: 35px; padding-top: 100px; padding-bottom: 100px"
          >
            <p>
              The content, assignment, physical hardware, etc. of the activities
              and activities created on the AKSIYON software are prepared within
              the framework of the necessary authorizations, scheduling, and
              status information is provided.
            </p>
          </div>
          <img
            class="rimg"
            src="../assets/aksiyon/5-30.jpg"
            style="width: 100%; margin-top: 35px"
          />
        </div>

        <div class="col-sm-6" style="">
          <img
            class="rimg"
            src="../assets/aksiyon/7-27.jpg"
            style="width: 100%; margin-top: 35px"
          />
          <div
            style="margin-top: 35px; padding-top: 100px; padding-bottom: 100px"
          >
            <p>
              The aim here is to transfer the projected event and all management
              activities to the digital environment on the AKSIYON software,
              with easy access to all content, tasks and other elements related
              to the event, instant communication about the activities, and the
              processes that will take a long time and lead to labor and
              resource wastage with other methods. more effectively and
              efficiently.
            </p>
          </div>
          <img
            class="rimg"
            src="../assets/aksiyon/9-21.jpg"
            style="width: 100%; margin-top: 35px"
          />
          <div
            style="margin-top: 35px; padding-top: 100px; padding-bottom: 100px"
          >
            <p>
              As a governance software, Aksiyon is a business management and
              team sharing software suitable for almost every company,
              institution or group. With its clean and modern structure,
              performance and safety, it has been prepared with comfortable use
              in mind. With its security, it offers special solutions to ensure
              that all your data is not lost and is safe.
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
  <enFooter />
  <a
    href="#"
    class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i
  ></a>
  <div id="preloader" />
</template>

<script>
import router from "../router";
export default {
  name: "Aksiyon",
  components: {
    enHeader,
    enFooter,
    TopBar,
  },

  data: function () {
    return {
      loader: "",
    };
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
    );
    document.body.appendChild(plugin);
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.min.js"
    );
    document.body.appendChild(plugin2);
  },
  methods: {
    navigate() {
      router.push({ name: "Aksiyon" });
    },
  },
};

import enHeader from "./enHeader";
import TopBar from "./topbar/TopBar";
import enFooter from "./enFooter";
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css";
@import "~boxicons/css/boxicons.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "../styles/style.css";
.right {
  position: absolute;
  top: 80px;
  right: 50px;
}
.rimg {
  border-radius: 50px;
}
</style>
